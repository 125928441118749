import * as React from 'react';
import { useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import { dosDecimales, showLoader, workWithLocalStorage } from '../../auxiliares/funciones';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import productos from '../../data/products.json'
import { isEmpty } from '../../auxiliares/funciones';
import Alerta from '../../componentes/alertas';
import ProductoMini from './productoMini';
import InputAdornment from "@mui/material/InputAdornment";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function Producto() {
  const location = useLocation();
  let producto = productos.find(p => p.supermodelId === location.pathname.split('/')[2]);
  const [cant, setCant] = React.useState(1);

  React.useEffect(() => {
    showLoader(false);
  });

  const modCant = (acc) =>{
    if(acc === '+'){
        if(cant < 4){
            setCant(cant+1);
        }
    }else{
        if(cant > 1){
            setCant(cant-1);
        }
    }
  }

  const seleccionaProd = () => {
    showLoader(true);
    producto.cant = cant;
    let carrito = workWithLocalStorage('get','carrito',null);
    if(carrito === null) carrito = {productos:[]};
    const index = carrito.productos.findIndex(p => p.supermodelId === producto.supermodelId);
    if(index > -1){
        carrito.productos[index] = producto;
    }else{
        carrito.productos.push(producto);
    }
    workWithLocalStorage('set','carrito',carrito);
    setTimeout(()=>{showLoader(false);}, 500);
  }

  return (
        <React.Fragment>
            <Container component="main" sx={{ mb: 4, mt: 2 }}>
            {isEmpty(producto) &&
                <Alerta tipo="error" titulo="Este artículo no existe"/>
            }
            {!isEmpty(producto) &&
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{ width: '100%', marginBottom: '16px', boxShadow: 'none', border: 'none' }}>
                        <CardHeader title={producto.webLabel} subheader={producto.natureLabel}/>
                        <CardMedia component="img" width="100%" image={producto.models[0].image.url} alt={producto.webLabel}/>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <CardContent sx={{ paddingBottom: 0, width: '50%' }}>
                                <Typography variant="body2" color="text.secondary">Precio: {dosDecimales(producto.models[0].price)} €</Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ color: 'green'}}>Stock: 4</Typography>
                                <Typography variant="body2" color="text.secondary">Ubicación: Montaña</Typography>
                            </CardContent>
                            <CardActions sx={{ width: '50%', marginTop: '10px'}}>
                                <TextField sx={{ width: '100%'}} type="number" value={cant} InputProps={{ inputProps: { min: 1, max: 4 }, 
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" color="primary" onClick={()=>modCant('+')}>
                                            <ControlPointIcon />
                                        </IconButton>
                                        <IconButton edge="end" color="primary" onClick={()=>seleccionaProd()}>
                                            <AddShoppingCartIcon />
                                        </IconButton>
                                    </InputAdornment>
                                  ),
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          <IconButton edge="start" color="primary" onClick={()=>modCant('-')}>
                                              <RemoveCircleOutlineIcon />
                                          </IconButton>
                                      </InputAdornment>
                                    )}}
                                  InputLabelProps={{ shrink: true }}  />
                            </CardActions>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="h6">Productos relacionados</Typography>
                        {productos.slice(0,5).map((prod,index)=>{
                            return <ProductoMini key={index} producto={prod}/>
                        })}
                    </Box>
                </Grid>
            </Grid>
            }
            </Container>
    </React.Fragment>
  );
}
