import React from 'react';
import places from '../../data/places.json'
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Buscador from '../../componentes/buscador';


export default function Home(props) {
        let place = places[props.place]

          if(place){
             return (<React.Fragment>
              <Container component="main" sx={{ mb: 4, mt: 2 }}>
               <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card sx={{ width: '100%'}}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {place.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {place.address}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {place.schedule}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div" sx={{ pt: 2 }}>
                        <PhoneIcon /> {place.phone}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ width: '100%' }}>
                    <Buscador/>
                  </Box>
                </Grid>
              </Grid>
              </Container>
            </React.Fragment>)
          }else{
            return (<Container component="main" maxWidth="sm" sx={{ mb: 4, mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card sx={{ width: '100%'}}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" sx={{ textAlign: 'center', mb: 0, mt: 1}}>
                        Selecciona una tienda
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>)
          }
}
