import * as React from 'react';
import { Link } from "react-router-dom";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PersonIcon from '@mui/icons-material/Person';
import Home from '@mui/icons-material/Home';
import Paper from '@mui/material/Paper';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ViewListIcon from '@mui/icons-material/ViewList';

import Lectura from '../../paginas/codbarras';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { workWithLocalStorage } from '../../auxiliares/funciones';
import Badge from '@mui/material/Badge';

export default function LabelBottomNavigation() {
  const posicion = 'right';
  let carrito = workWithLocalStorage('get','carrito',null);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [carritoQty, setCarritoQty] = React.useState(carrito !== null ? carrito.productos.length : 0);

  const originalSetItem = localStorage.setItem
  localStorage.setItem = function() {
    const event = new Event('storageChange')
    document.dispatchEvent(event)
    originalSetItem.apply(this, arguments)
  }
  const originalRemoveItem = localStorage.removeItem
  localStorage.removeItem = function() {
    const event = new Event('storageChange')
    document.dispatchEvent(event)
    originalRemoveItem.apply(this, arguments)
  }

  const setLocalItem = () => {
    /** local storage update is not that fast */
    /** it makes sure that we are getting the new value  */
    setTimeout(() => {
      let carrito = workWithLocalStorage('get','carrito',null);
      setCarritoQty(carrito !== null ? carrito.productos.length : 0)
    }, 50)
  }

  React.useEffect(() => {
    document.addEventListener('storageChange',setLocalItem,false)
    return () => document.removeEventListener('storageChange', setLocalItem)
  }, [])

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setShowDrawer(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/historial">
              <ListItemIcon>
                <ViewListIcon />
              </ListItemIcon>
              <ListItemText primary={'Historial de compras'} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/forma-pago">
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary={'Forma de Pago'} />
            </ListItemButton>
          </ListItem>
      </List>
      <Divider />
      <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/datos">
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary={'Datos personales'} />
            </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );

  const [value, setValue] = React.useState('Inicio');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const style = {
    position: 'fixed',
    width: '90%',
    top:'40px',
    //bottom: '20px',
    transform: 'translate(2.5%, 0%)',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '10px',
    paddingBottom: '20px',
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }} elevation={3}>
      <BottomNavigation showLabels value={value} onChange={handleChange}>
        <BottomNavigationAction label="Inicio" value="Inicio" icon={<Home />} component={Link} to="/"/>
        <BottomNavigationAction label="Leer" value="Leer" icon={<QrCodeIcon />} /*component={Link} to="/lectura"*/onClick={handleOpen}/>
        {carritoQty === 0 ?
          <BottomNavigationAction label="Carrito" value="Carrito" icon={<ShoppingCartIcon />} component={Link} to="/carrito"/>
        : <BottomNavigationAction label="Carrito" value="Carrito" icon={<Badge badgeContent={carritoQty} color="primary"><ShoppingCartIcon /></Badge>} component={Link} to="/carrito"/>}
        <BottomNavigationAction label="Perfil" value="Perfil" icon={<PersonIcon />}  onClick={toggleDrawer(posicion, true)}/>
      </BottomNavigation>
      <SwipeableDrawer
          anchor={posicion}
          open={showDrawer}
          onClose={toggleDrawer(posicion, false)}
          onOpen={toggleDrawer(posicion, true)}
        >
          {list(posicion)}
      </SwipeableDrawer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Lectura />
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
            Apunta al código de barras del producto hasta que te redirija a la ficha del producto
          </Typography>
          <Button variant="contained" color="error" onClick={handleClose}>Cancelar</Button>
        </Box>
      </Modal>
    </Paper>
  );
}
