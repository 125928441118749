import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Button, Typography, Container } from '@mui/material';

export default function Page404() {
  return (
    <React.Fragment>
        <Container>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                <Typography variant="h3" paragraph>
                  Sorry, page not found!
                </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
                Be sure to check your spelling.
              </Typography>

              Error 404

              <Button to="/" size="large" variant="contained" component={RouterLink}>
                Go to Home
              </Button>
            </Box>
        </Container>
    </React.Fragment>
  );
}
