import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import { dosDecimales } from '../../auxiliares/funciones';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Collapse from '@mui/material/Collapse';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProductoMini(props) {
  const [expanded, setExpanded] = React.useState(false);
  const producto = props.producto;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: '100%', display: 'flex', marginBottom: '16px', boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)' }}>
        <CardMedia component="img" sx={{ width: 90 }} image={producto.models[0].image.url}/>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ paddingBottom: 0, textDecoration: 'none' }} component={Link} to={"/producto/"+producto.supermodelId} onClick={()=>{window.scrollTo(0)}}>
                <Typography>{producto.webLabel}</Typography>
                <Typography variant="body2" color="text.secondary">Precio: {dosDecimales(producto.models[0].price)} €</Typography>
            </CardContent>
            <CardActions>
                <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                    <GpsFixedIcon />
                </ExpandMore>
                <Typography color="text.secondary" sx={{ color: 'green'}}>Stock: 4</Typography>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{ paddingTop: 0, paddingBottom: '10px !important' }}>
                    <Typography variant="body2" color="text.secondary">Ubicación: Montaña</Typography>
                </CardContent>
            </Collapse>
        </Box>
    </Card>
  );
}
