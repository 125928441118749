import React, { Component } from 'react';
import { authProtectedRoutes, publicRoutes } from "./rutas/";
import { isUserAuthenticated, getProfile } from './auxiliares/funciones';
import AppRoute from "./rutas/rutas";
import "./App.css";

import PrincipalLayout from "./plantillas/principal/";
import LoginLayout from "./plantillas/login";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tema from './tema';
import Page404 from "./paginas/404";

const tema = createTheme(Tema)

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    let profileID = 0
    if(isUserAuthenticated()){
      profileID = getProfile()
    }

    return (
    <ThemeProvider theme={tema}>
        {publicRoutes.map((route, idx) => {
          return (
            <AppRoute
              path={route.path}
              component={route.component}
              layout={route.layout ? route.layout : PrincipalLayout}
              key={idx}
              isAuthProtected={false}
              exact
            />
          )})}

          {authProtectedRoutes.filter(route => route.profiles.includes(profileID) ).map((route, idx) => {
            return route.exact !== undefined ?
            <AppRoute
              path={route.path}
              component={route.component}
              layout={route.layout ? route.layout : PrincipalLayout}
              key={idx}
              isAuthProtected={true}
              exact
            />
            :<AppRoute
              path={route.path}
              component={route.component}
              layout={route.layout ? route.layout : PrincipalLayout}
              key={idx}
              isAuthProtected={true}
            />
          })}
          {isUserAuthenticated() ? <AppRoute layout={PrincipalLayout} component={Page404} />
          : <AppRoute layout={LoginLayout} component={Page404} /> }
    </ThemeProvider>
  );
}
}

export default App;
