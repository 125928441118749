import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Mensaje from '../../auxiliares/mensaje';

class FormaPago extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mensaje: {
                visible: false,
                titulo: '',
                texto:''
            }
        }
    }

    handleSave = () =>{
        this.setState({
            mensaje: {
                visible: true,
                titulo: 'Datos guardados',
                texto:'Se han guardados los datos de la tarjeta'
            }
        }) 
    }

    ocultaMensaje = () =>{
        this.setState({
            mensaje: {
                visible: false,
                titulo: '',
                texto:''
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {   this.state.mensaje.visible &&
                    <Mensaje titulo={this.state.mensaje.titulo} texto={this.state.mensaje.texto} ocultaMensaje={this.ocultaMensaje}/>
                }
                 <Container component="main" sx={{ mb: 4, mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Configurar forma de pago
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                            <TextField value="Luisito perez lopez"
                                required
                                id="cardName"
                                label="Propietario de la tarjeta"
                                fullWidth
                                autoComplete="cc-name"
                                variant="standard"
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField value="4450 4512 1254 1233"
                                required
                                id="cardNumber"
                                label="Nº de tarjeta"
                                fullWidth
                                autoComplete="cc-number"
                                variant="standard"
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField value="06/26"
                                required
                                id="expDate"
                                label="Fecha de cad."
                                fullWidth
                                autoComplete="cc-exp"
                                variant="standard"
                            />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextField value="000"
                                required
                                id="cvc"
                                label="CVC"
                                helperText="El código CVC es un grupo de 3 números situado en el reverso de la tarjeta de crédito."
                                fullWidth
                                autoComplete="cc-csc"
                                variant="standard"
                            />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={()=> this.handleSave()} sx={{ mt: 3, ml: 1, float: 'right' }} >
                                Grabar
                                </Button>
                            </Grid>
                        </Grid>
                </Container>
            </React.Fragment>
        );
    }
}

export default FormaPago;
