import React from 'react';
import productos from '../data/products.json'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

import { useNavigate } from "react-router-dom";

export default function Buscador(props) {
        const [open, setOpen] = React.useState(false);
        const [options, setOptions] = React.useState([]);
        const [loading, setLoading] = React.useState(false);
        const [value, setValue] = React.useState('');
        const navigate = useNavigate();

        const StyledPopper = styled(Popper)(({ theme }) => ({
          '& .MuiAutocomplete-groupLabel': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          },
          '& .MuiAutocomplete-option' : {
            borderBottom: '1px solid #ccc'
          },
        }));

        const filtraResultados = (e) =>{
          const val = e.target.value.toLowerCase();
          setValue(val);
          if(val.length < 3){
            setOptions([]);
          }else{
            setLoading(true)
            const prods = productos.filter(p => p.natureLabel.toLowerCase().includes(val) || p.webLabel.toLowerCase().includes(val));
            setOptions(prods);
            setLoading(false)
          }
        }

        const buscar = (e) => {
          if(e.keyCode === 13){
            navigate('/productos/busqueda/',{ state: {texto: value}});
          }
       }

             return (<Autocomplete sx={{ width: '100%' }} open={open} onOpen={() => { setOpen(true); }} onClose={() => { setOpen(false); }}
                          isOptionEqualToValue={(option, value) => option.webLabel === value.title}
                          options={options.sort((a, b) => -b.brand.label.localeCompare(a.brand.label))}
                          groupBy={(option) => option.brand.label} 
                          PopperComponent={StyledPopper} noOptionsText="Sin resultados"
                          onChange={(event, value) => {navigate('/producto/'+value.supermodelId)}}
                          getOptionLabel={(option) => option.webLabel} 
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onKeyUp={(e) => buscar(e)}
                              onChange={(e) => { filtraResultados(e); }}
                              label="Búsqueda"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />)
}
