import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Mensaje from '../../auxiliares/mensaje';

class Datos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mensaje: {
                visible: false,
                titulo: '',
                texto:''
            }
        }
    }

    handleSave = () =>{
        this.setState({
            mensaje: {
                visible: true,
                titulo: 'Datos guardados',
                texto:'Se han guardados los datos personales'
            }
        }) 
    }

    ocultaMensaje = () =>{
        this.setState({
            mensaje: {
                visible: false,
                titulo: '',
                texto:''
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {   this.state.mensaje.visible &&
                    <Mensaje titulo={this.state.mensaje.titulo} texto={this.state.mensaje.texto} ocultaMensaje={this.ocultaMensaje}/>
                }
                 <Container component="main" sx={{ mb: 4, mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Datos personales
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                        <TextField value="Luisito"
                            required
                            id="firstName"
                            name="firstName"
                            label="Nombre"
                            fullWidth
                            autoComplete="given-name"
                            variant="standard"
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField value="Perez Lopez"
                            required
                            id="lastName"
                            name="lastName"
                            label="Apellidos"
                            fullWidth
                            autoComplete="family-name"
                            variant="standard"
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField value="Calle falsa"
                            required
                            id="address1"
                            name="address1"
                            label="Dirección línea 1"
                            fullWidth
                            autoComplete="shipping address-line1"
                            variant="standard"
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField value="Nº 123"
                            id="address2"
                            name="address2"
                            label="Direccion línea 2"
                            fullWidth
                            autoComplete="shipping address-line2"
                            variant="standard"
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField value="Valencia"
                            required
                            id="city"
                            name="city"
                            label="Ciudad"
                            fullWidth
                            autoComplete="shipping address-level2"
                            variant="standard"
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField value="Valencia"
                            id="state"
                            name="state"
                            label="Provincia"
                            fullWidth
                            variant="standard"
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField value="46001"
                            required
                            id="zip"
                            name="zip"
                            label="Cód. Postal"
                            fullWidth
                            autoComplete="shipping postal-code"
                            variant="standard"
                        />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={()=> this.handleSave()} sx={{ mt: 3, ml: 1, float: 'right' }} >
                               Grabar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }
}

export default Datos;
