import React from 'react';
import Resumen from '../cesta/resumen';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export default function Historial() {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        console.log(expanded)
    };

    const pedidos = [
        {
            numero: '234',
            total: '1064.09',
            tienda: 'Decathlon City Valencia',
            fecha: '21/07/2022',
            hora: '18:41',
            productos: [
                {
                    desc: 'Tabla de stand up paddle',
                    name: 'Stand up paddle Paddle surf Azul 10\'6" | Beast',
                    price: '314.1',
                  },
                  {
                    desc: 'Tabla de stand up paddle',
                    name: 'Stand Up Paddle Rígida Tahe Outdoor Beach Cross 11\' 260 L',
                    price: '749.99',
                  }
            ]
        },
        {
            numero: '480',
            total: '298.69',
            tienda: 'Decathlon City Valencia',
            fecha: '25/07/2022',
            hora: '10:22',
            productos: [
                {
                    desc: 'Tabla de stand up paddle',
                    name: 'Stand Up Paddle Board Tabla de surf Makani Blue 320 x 82 x 15 cm',
                    price: '298.69',
                  }
            ]
        }
    ];

    return (
        <React.Fragment>
            <Container component="main" sx={{ mb: 4, mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Histórico de pedidos
                        </Typography>
            {pedidos.map((ped,index)=>{
               return <Accordion key={index} expanded={expanded === ped.numero} onChange={handleChange(ped.numero)}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={ped.numero+"bh-content"}
                        id={ped.numero+"bh-header"}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{ color: 'text.secondary' }}>{ped.tienda}</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography>Total: {ped.total} €</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{ color: 'text.secondary' }}>{ped.fecha} {ped.hora}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails sx={{ borderTop: '1px dashed #ccc'}}>
                            <Resumen pedido={ped} />
                        </AccordionDetails>
                    </Accordion>
            })}
            </Container>
        </React.Fragment>
    );
}

