import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { workWithLocalStorage } from '../../auxiliares/funciones';
import places from '../../data/places.json'

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { useNavigate } from "react-router-dom";

const styleSelect = {
  '& svg': {
    color: 'white'
  },
  m: 1,
  minWidth: 120,
  width: '100%',
  borderBottom: '1px solid white'
};

export default function SearchAppBar(props) {
  let lsConfig = workWithLocalStorage('get','lsConfig',null);
  lsConfig = lsConfig ? lsConfig : { place: -1 };
  const [place, setPlace] = React.useState(lsConfig.place);
  const [select, setSelect] = React.useState(lsConfig.place === -1);

  const handleChange = (event) => {
    setPlace(event.target.value);
    setSelect(event.target.value === -1)
    lsConfig.place = event.target.value;
    workWithLocalStorage('set','lsConfig',lsConfig);
    props.changePlace()
  };

  const backBtn = window.history.state.idx > 0;
  const navigate = useNavigate();

  return (
    <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 999 }} elevation={3}>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {backBtn &&
          <IconButton size="large" edge="start" color="inherit" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          }
          {select ?
          <FormControl sx={styleSelect} variant="standard" size="small">
            <Select value={place} label="Tienda" onChange={handleChange} style={{color: 'white'}}>
              <MenuItem value={-1}><em>Selecciona una tienda</em></MenuItem>
              {places.map((item,index)=>{
                return <MenuItem key={index} value={index}>{places[index].name}</MenuItem>
              })}
            </Select>
          </FormControl>
          :<>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {places[lsConfig.place].name}
          </Typography>
          <IconButton size="large" edge="start" color="inherit" sx={{ position: 'fixed', right: 0 }} onClick={() => setSelect(true)}>
            <EditLocationAltIcon />
          </IconButton>
          </>
          }
        </Toolbar>
      </AppBar>
    </Box>
    </Paper>
  );
}
