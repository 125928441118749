import React, { Component } from 'react';
import CONFIG from '../../CONFIG.json';
import withRouter from '../../auxiliares/withRouter';
import Nav from './nav';
import Botones from './botones';
import { workWithLocalStorage } from '../../auxiliares/funciones';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';

class Principal extends Component {
    constructor(props) {
        let lsConfig = workWithLocalStorage('get','lsConfig',null);
        lsConfig = lsConfig ? lsConfig : { place: -1 };
        super(props);
        this.state={
          place: lsConfig.place
        };
    }

    changePlace = () => {
      const lsConfig = workWithLocalStorage('get','lsConfig',null);
      this.setState({ place: lsConfig.place })
    }

    componentWillMount(){
      document.body.classList.add("bodyLogin");
    }

    componentDidMount(){
        document.title = CONFIG.nombre +" | "+CONFIG.descripcion;
    }



    render() {
        return <>
              <Backdrop id="loader" sx={{ color: '#012d3a', backgroundColor: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'none' }} open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Nav changePlace={this.changePlace}/>
              <Box sx={{ position: 'fixed', top: 56, bottom: 56, overflowY: 'auto', width: '100%', right: '0px' }}>
                {React.cloneElement(this.props.children, { place : this.state.place })}
              </Box>
              <Botones />
        </>;
    }
}

export default (withRouter(Principal));
