import Home from '../paginas/home';
import Lectura from '../paginas/codbarras';
import Carrito from '../paginas/cesta';
import Datos from '../paginas/perfil/datos';
import Historial from '../paginas/perfil/historial';
import FormaPago from '../paginas/perfil/formaPago';
import Producto from '../paginas/productos/producto';
import Productos from '../paginas/productos/productos';

import E404 from "../paginas/404";
import E500 from "../paginas/500";

//import SinPlantilla from "../plantillas/sin_plantilla"

const authProtectedRoutes = [

  /*{ exact: true, path: "/",  component: () => <Navigate to="/login" /> ,profiles:[1]},*/
  /*{ exact: true, path: "/app", component: Partidos, profiles:[1] },


  { exact: true, path: "/jugadores", component: Jugadores , name:'Jugadores', profiles:[1], icon:'mdi mdi-account-supervisor-outline',menu:'Jugadores'},
  { exact: true, path: "/jugador", component: Jugador , name:'Crear Jugador', profiles:[1], icon:'mdi mdi-account-multiple-plus-outline',menu:'Jugadores'},
  { exact: true, path: "/jugador/:id", component: Jugador , name:'Detalle Jugador', profiles:[1], icon:'mdi mdi-account-multiple-plus-outline'},

  { exact: true, path: "/partidos", component: Partidos , name:'Partidos', profiles:[1], icon:'mdi mdi-clipboard-list-outline', menu:'Partidos'},
  { exact: true, path: "/historico", component: Historico , name:'Histórico', profiles:[1], icon:'mdi mdi-clipboard-list', menu:'Partidos'},
  { exact: true, path: "/partido", component: Partido , name:'Crear Partido', profiles:[1], icon:'mdi mdi-clipboard-list', menu:'Partidos'},
  { exact: true, path: "/partido/:id", component: Partido , name:'Detalle Partido', profiles:[1], icon:'mdi mdi-clipboard-list'},
  { exact: true, path: "/partido-cierre/:id", component: CierrePartido , name:'Cierre Partido', profiles:[1], icon:'mdi mdi-clipboard-list'},

  { exact: true, path: "/asistencias", component: Asistencias , name:'Asistencias', profiles:[1], icon:'mdi mdi-calendar-weekend-outline', menu:'Estadísticas'},
  { exact: true, path: "/faltas", component: Faltas , name:'Faltas', profiles:[1], icon:'mdi mdi-account-badge-horizontal-outline', menu:'Estadísticas'},
  { exact: true, path: "/deudas", component: Deudas , name:'Deudas', profiles:[1], icon:'mdi mdi-cash', menu:'Estadísticas'},*/

  //OTROS 2
  { exact: true, path: "/500", component: E500 ,name:'Error 500', profiles:[1]}
];

const publicRoutes = [
  { exact: true, path: "/",  component: Home },
  { exact: true, path: "/lectura",  component: Lectura },
  { exact: true, path: "/carrito",  component: Carrito },
  { exact: true, path: "/historial",  component: Historial },
  { exact: true, path: "/forma-pago",  component: FormaPago },
  { exact: true, path: "/datos",  component: Datos },
  { exact: true, path: "/producto/:id",  component: Producto },
  { exact: true, path: "/productos/busqueda/",  component: Productos },
  { path: "/404", component: E404 }
];

export { authProtectedRoutes, publicRoutes };
