import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// Get all Auth methods
import { isUserAuthenticated } from '../auxiliares/funciones';

const AppRoute = ({ component: Component, isAuthProtected, layout : Layout, ...rest}, props) => {
  if (isAuthProtected && !isUserAuthenticated()) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} exact />
    );
  }else{
    return(
      <Routes>
        <Route
          {...rest}
          element={
              <Layout>
                <Component {...props} />
              </Layout>
          }
        />
      </Routes>
    )
  }
};

export default AppRoute;
