import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { workWithLocalStorage, dosDecimales, showLoader } from '../../auxiliares/funciones';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Alerta from '../../componentes/alertas';
import Backdrop from '@mui/material/Backdrop';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InputAdornment from "@mui/material/InputAdornment";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

export default function Historial() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [carrito, setCarrito] = React.useState(workWithLocalStorage('get','carrito',null) === null ? {productos:[]} : workWithLocalStorage('get','carrito',null));
    //let carrito = workWithLocalStorage('get','carrito',null);
    //if(carrito === null) carrito = {productos:[]};
    const total = () =>{
        let tot = 0.00;
        for(var i=0;i<carrito.productos.length;i++){
            tot = tot + carrito.productos[i].cant * carrito.productos[i].models[0].price
        }
        return tot;
    }
    
    const modCant = (index,acc) =>{
        let cant = carrito.productos[index].cant;
        if(acc === '+'){
            if(cant < 4){
                carrito.productos[index].cant = cant+1;
                workWithLocalStorage('set','carrito',carrito);
                showLoader(true);
                navigate(0);
            }
        }else{
            if(cant > 1){
                carrito.productos[index].cant = cant-1;
                workWithLocalStorage('set','carrito',carrito);
                showLoader(true);
                navigate(0);
            }
        }
    }

    const remProd = (index) =>{
        carrito.productos.splice(index,1);
        workWithLocalStorage('set','carrito',carrito);
        showLoader(true);
        navigate(0);
    }

    React.useEffect(() => {showLoader(false);},[]);

    const handleClose = () => {
        setOpen(false);
    };

    const pagar = () =>{
        showLoader(true);
        workWithLocalStorage('remove','carrito');
        setTimeout(()=>{setCarrito({productos:[]});showLoader(false);setOpen(true);},500);
    }

    return (
        <React.Fragment>
            <Backdrop sx={{ color: '#012d3a', backgroundColor: 'white', zIndex: 999999 }} open={open} onClick={handleClose}>                
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'center'}}>
                    <CheckCircleOutlineIcon color="success" sx={{ width: '50%', height: '50%' }} /><br/>
                    Pedido pagado!<br/>
                    Ya puedes salir del local.
                </Typography>
            </Backdrop>
            <Container component="main" sx={{ mb: 4, mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Contenido de la cesta
                </Typography>
                {carrito.productos.length === 0 && <Alerta tipo="info" titulo="No hay articulos en la cesta"/>}
                {carrito.productos.length !== 0 &&
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {carrito.productos.map((prod,index) => {
                    return <React.Fragment key={index}>
                        <ListItem  alignItems="flex-start" sx={{ paddingLeft: 0, paddingRight: 0}}>
                            <ListItemAvatar>
                                <Avatar variant="square" alt={prod.webLabel} src={prod.models[0].image.url} />
                            </ListItemAvatar>
                            <ListItemText
                            primary={prod.webLabel}
                            secondary={
                                <React.Fragment>
                                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                        {prod.natureLabel}<br/>
                                        {dosDecimales(prod.models[0].price)} €  x{prod.cant}
                                    </Typography>
                                    <TextField sx={{ width: '50%', float: 'right'}} type="number" value={prod.cant} InputProps={{ inputProps: { min: 1, max: 4 }, 
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" color="primary" onClick={()=>modCant(index,'+')}>
                                            <ControlPointIcon />
                                        </IconButton>
                                        <IconButton edge="end" color="error" onClick={()=>{remProd(index)}}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </InputAdornment>
                                  ),
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          <IconButton edge="start" color="primary" onClick={()=>modCant(index,'-')}>
                                              <RemoveCircleOutlineIcon />
                                          </IconButton>
                                      </InputAdornment>
                                    )}}
                                  InputLabelProps={{ shrink: true }}  />
                                </React.Fragment>
                            }
                            />                            
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                    })}
                        <ListItem sx={{ py: 1, px: 0 }}>
                            <ListItemText primary={
                                <React.Fragment>
                                    <Button variant="contained" sx={{ float:'left'}} color="primary" onClick={()=>{pagar()}}>Pagar</Button>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 700, float: 'right' }}>
                                        Total: {total()} €
                                    </Typography>
                                </React.Fragment>
                             } />
                        </ListItem>
                    </List>
                }
            </Container>
        </React.Fragment>
    );
}

