import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

class FormaPago extends Component {

    render() {
        const ped = this.props.pedido;

        return (
            <React.Fragment>
                    {ped.numero !== '' &&
                    <Typography variant="h6" gutterBottom>
                        Resumen pedido #{ped.numero}
                    </Typography>
                    }
                    <List disablePadding>
                        {ped.productos.map((product,index) => (
                        <ListItem key={index} sx={{ py: 1, px: 0 }}>
                            <ListItemText primary={product.name} secondary={product.desc} />
                            <Typography variant="body2" sx={{ whiteSpace: 'nowrap'}}>{product.price} €</Typography>
                        </ListItem>
                        ))}

                        <ListItem sx={{ py: 1, px: 0 }}>
                        <ListItemText primary="Total" />
                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                            {ped.total} €
                        </Typography>
                        </ListItem>
                    </List>
                    <Grid container spacing={2}>
                        <Grid item container direction="column" xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                            Detalle
                        </Typography>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography gutterBottom>Tienda</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography gutterBottom>{ped.tienda}</Typography>
                            </Grid>
                            {ped.fecha !== '' &&
                            <>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Fecha</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{ped.fecha}</Typography>
                                </Grid>
                            </>
                            }
                            {ped.hora !== '' &&
                            <>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Hora</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{ped.hora}</Typography>
                                </Grid>
                            </>
                            }
                        </Grid>
                        </Grid>
                    </Grid>
            </React.Fragment>
        );
    }
}

export default FormaPago;
