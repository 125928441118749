import React from 'react';
import { isEmpty } from '../../auxiliares/funciones';
import productos from '../../data/products.json'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ProductoMini from './productoMini';
import { useLocation } from "react-router-dom";
import Alerta from '../../componentes/alertas';
import Buscador from '../../componentes/buscador';

export default function Productos(props) {

    const filtraResultados = (texto) =>{
        return productos.filter(p => p.natureLabel.toLowerCase().includes(texto) || p.webLabel.toLowerCase().includes(texto));
    }
    
    const location = useLocation();
    const prods = filtraResultados(location.state.texto);

    return (
        <React.Fragment>
        <Container component="main" sx={{ mb: 4, mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Resultado de búsqueda<br/>"{location.state.texto}"
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 2}}>
                    {prods.length} Resultados encontrados
                </Typography>
                {isEmpty(prods) &&
                    <>
                        <Alerta tipo="warning" titulo="Sin resultados"/>
                        <Buscador sx={{ mt: 2}}/>
                    </>
                }
                {prods.map((producto,index)=>{
                    return <ProductoMini key={index} producto={producto}/>
                })}
        </Container>
        </React.Fragment>
    );
}
