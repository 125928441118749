import React, { Component } from 'react';
import CONFIG from '../CONFIG.json';
import withRouter from '../auxiliares/withRouter';

class LoginLayout extends Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    componentDidMount(){
        document.title = CONFIG.nombre +" | "+CONFIG.descripcion;
    }
    render() {
        return <React.Fragment>
              <div id="preloader">
                  <div id="status">
                      <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                      </div>
                  </div>
              </div>
            {this.props.children}
        </React.Fragment>;
    }
}

export default (withRouter(LoginLayout));
