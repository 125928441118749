 import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { showLoader } from '../../auxiliares/funciones';
import { useNavigate } from "react-router-dom";

function App() {
  const [stopStream, setStopStream] = useState(false)
  const navigate = useNavigate();

  return (
        <BarcodeScannerComponent
          //width={"100%"}
          height={450}
          onUpdate={(err, result) => {
            if (result) {
              setStopStream(true)
              showLoader(true);
              navigate('/producto/'+result.text);
              navigate(0);
            }
          }}
          stopStream={stopStream} />
  );
}

export default App;
