const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#012d3a',
      light: '#094254',
      dark: '#1e5262',
    },
    secondary: {
      main: '#e38e7b',
      light: '#e38e7b',
      dark: '#cd4527',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    text: {
      primary: '#545454',
    },
    background: {
      default: '#043e50',
    },
  },
};

export default themeOptions;
